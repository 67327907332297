body {
  padding: 0;
  margin: 0;
  font-size: 13px;
}
h2 {
  font-size: 20px !important;
}
h3 {
  font-size: 16px !important;
}
.Layouts {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

.Section {
  display: none;
}
.Section.active {
  display: block;
}

input:not([type]):focus:not([readonly]),
input[type="text"]:not(.browser-default):focus:not([readonly]),
input[type="password"]:not(.browser-default):focus:not([readonly]),
input[type="email"]:not(.browser-default):focus:not([readonly]),
input[type="url"]:not(.browser-default):focus:not([readonly]),
input[type="time"]:not(.browser-default):focus:not([readonly]),
input[type="date"]:not(.browser-default):focus:not([readonly]),
input[type="datetime"]:not(.browser-default):focus:not([readonly]),
input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
input[type="tel"]:not(.browser-default):focus:not([readonly]),
input[type="number"]:not(.browser-default):focus:not([readonly]),
input[type="search"]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #2196f3 !important;
  -webkit-box-shadow: 0 1px 0 0 #2196f3 !important;
  box-shadow: 0 1px 0 0 #2196f3 !important;
}

.input-field.col label,
.input-field label:not(.label-icon).active {
  color: #2196f3 !important;
}

[type="checkbox"]:checked + label:before {
  border-right: 2px solid #2196f3 !important;
  border-bottom: 2px solid #2196f3 !important;
}

.hidden {
  display: none;
}
